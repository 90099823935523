<script>
/**
 * RAFC-N Cards component
 */
import moment from "moment";
import { authComputed } from "@/state/helpers";
export default {
  props: ["centres", "loaderData"],
  components: {},
  data() {
    return {
      centresList: {},
      dateN: null,
    };
  },
  created() {
    //this.chargeRafc(this.rafc);
  },
  computed: {
    ...authComputed,
    dateNow() {
      return moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  mounted() {},
  methods: {
    chargeRafc(data) {
      this.centresList = data;
    },
  },
  watch: {
    centres(newVal) {
      /* let rafcData = {
        sp1: {
          name: "Supercongélateur 1",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp2: {
          name: "Supercongélateur 2",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp3: {
          name: "Supercongélateur 3",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp4: {
          name: "Supercongélateur 4",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp5: {
          name: "Supercongélateur 5",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp6: {
          name: "Supercongélateur 6",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp7: {
          name: "Supercongélateur 7",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp8: {
          name: "Supercongélateur 8",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp9: {
          name: "Supercongélateur 9",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp10: {
          name: "Supercongélateur 10",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp11: {
          name: "Supercongélateur 11",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp12: {
          name: "Supercongélateur 12",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp13: {
          name: "Supercongélateur 13",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp14: {
          name: "Supercongélateur 14",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        sp15: {
          name: "Supercongélateur 15",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        chn28: {
          name: "Chambre froide negative 28",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls2: {
          name: "Supercongélateur BSLS 2",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls3: {
          name: "Supercongélateur BSLS 3",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls5: {
          name: "Supercongélateur BSLS 5",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls6: {
          name: "Supercongélateur BSLS 6",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls7: {
          name: "Supercongélateur BSLS 7",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls8: {
          name: "Supercongélateur BSLS 8",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls9: {
          name: "Supercongélateur BSLS 9",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls10: {
          name: "Supercongélateur BSLS 10",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls11: {
          name: "Supercongélateur BSLS 11",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls12: {
          name: "Supercongélateur BSLS 12",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls13: {
          name: "Supercongélateur BSLS 13",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls14: {
          name: "Supercongélateur BSLS 14",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls4: {
          name: "Hôpital militaire Rabat 1",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        bsls1: {
          name: "Hôpital militaire Rabat 2",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
      };
      Object.keys(newVal).map((obj) => {
        if (rafcData[obj]) {
          rafcData[obj] = {
            ...rafcData[obj],
            moyTemp: newVal[obj].moy ? newVal[obj].moy.toFixed(2) : "-",
            maxTemp: newVal[obj].max ? newVal[obj].max.toFixed(2) : "-",
            minTemp: newVal[obj].min ? newVal[obj].min.toFixed(2) : "-",
            stockType: newVal[obj].stockType,
            state: newVal[obj].stockType == false
              ? "secondary"
              : rafcData[obj].name.toLocaleLowerCase().includes("super") || rafcData[obj].name.toLocaleLowerCase().includes("hôpital")
                ? newVal[obj].moy > -90 && newVal[obj].moy < -60
                  ? "success"
                  : "danger"
                : newVal[obj].moy > -22 && newVal[obj].moy < -18
                ? "success"
                : "danger",
          };
        }
      });
      this.rafcList = Object.values(rafcData); */

      this.centresList = newVal;
      this.dateN = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    },
  },
};
</script>

<template>
  <div style="min-height: 750px">
    <div
      v-if="loaderData == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="loaderData == false" class="row mb-0">
      <div
        class="col-12"
        v-for="(centre, index) in Object.keys(centresList)"
        :key="index"
      >
        <h5>{{centre.split('-p-')[1]}}</h5>
        <div class="row">
          <div
            v-for="(ref, index) in Object.keys(centresList[centre])"
            :key="index"
            class="col-6 col-lg-4 col-xl-2"
          >
            <div class="card shadow-lg">
            <div
              class="
                card-header
                rounded
                text-white
                font-size-15 font-weight-bolder
                p-0
                ellipsisText
              "
              :class="{
                'bg-success': `${centresList[centre][ref].state}` == `success`,
                'bg-danger': `${centresList[centre][ref].state}`  == `danger`,
                'bg-secondary': `${centresList[centre][ref].state}`  == `secondary`,
              }"
            >
              <img
                class="m-1"
                :src="require('@/assets/images/cube.svg')"
                width="20"
              />
              {{ ref }}
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-4 text-center p-0 font-size-15 ellipsisText">
                  {{ centresList[centre][ref].min }} °C<br />Min
                </div>
                <div
                  class="col-4 text-center p-0 ellipsisText"
                  :class="{
                    'text-success': `${centresList[centre][ref].state}` == `success`,
                    'text-danger': `${centresList[centre][ref].state}`  == `danger`,
                    'text-secondary': `${centresList[centre][ref].state}`  == `secondary`,
                  }"
                  style="
                    font-size: 19px;
                    font-weight: 900;
                    text-shadow: 0px 1px, 1px 0px, 1px 0px;
                  "
                >
                  <b>{{ centresList[centre][ref].moy }} °C</b>
                </div>
                <div class="col-4 text-center p-0 font-size-15 ellipsisText">
                  {{ centresList[centre][ref].max }} °C<br />Max
                </div>
              </div>
                <div class="row">
                  <div
                    class="col-12 text-center p-0 font-weight-lighter ellipsisText"
                  >
                    {{ dateN }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- <div
        v-for="(item, index) in Object.keys(centresList)"
        :key="index"
        class="col-6 col-lg-4 col-xl-2"
      >
        <div class="card shadow-lg">
          <div
            class="
              card-header
              rounded
              text-white
              font-size-15 font-weight-bolder
              p-0
              ellipsisText
            "
            :class="{
              'bg-success': `${item.state}` == `success`,
              'bg-danger': `${item.state}`  == `danger`,
              'bg-secondary': `${item.state}`  == `secondary`,
            }"
          >
            <img
              class="m-1"
              :src="require('@/assets/images/cube.svg')"
              width="20"
            />
            {{ item.name }}
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4 text-center p-0 font-size-15 ellipsisText">
                {{ item.minTemp }} °C<br />Min
              </div>
              <div
                class="col-4 text-center p-0 ellipsisText"
                :class="{
                  'text-success': `${item.state}` == `success`,
                  'text-danger': `${item.state}`  == `danger`,
                  'text-secondary': `${item.state}`  == `secondary`,
                }"
                style="
                  font-size: 19px;
                  font-weight: 900;
                  text-shadow: 0px 1px, 1px 0px, 1px 0px;
                "
              >
                <b>{{ item.moyTemp }} °C</b>
              </div>
              <div class="col-4 text-center p-0 font-size-15 ellipsisText">
                {{ item.maxTemp }} °C<br />Max
              </div>
            </div>
            <div class="row">
              <div
                class="col-12 text-center p-0 font-weight-lighter ellipsisText"
              >
                {{ dateN }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<style scoped>
.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
</style>