<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

//import RafcData from "./rafc-data";
import RafcCards from "./rafc-negatif-cards";

import {
  layoutComputed,
  authComputed,
  layoutMethods,
  rafcMethods,
  rafcComputed,
} from "@/state/helpers";
/**
 * Rafc Negatif component
 */
export default {
  page: {
    title: "Super-Congélateur",
    meta: [{ name: "Super-Congélateur" }],
  },
  components: {
    Layout,
    PageHeader,
    RafcCards,
    //RafcData,
  },
  mounted() {
    this.changeMenuType();
  },
  created() {
    this.retrieveRafc();
    this.intervalCentres = setInterval(() => {
      this.retrieveRafc();
    }, 1000 * 60 * 5);
  },
  data() {
    return {
      title: "Super-Congélateur",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Super-Congélateur",
          active: true,
        },
      ],

      loaderData: true,
      intervalCentres: null,

      centresData: {},

      manageData: {
        room: null,
        stockType: null,
      },

      err: {
        room: null,
        stockType: null,
      },

      chambresRAFC: [
        { value: null, text: "-- Chambre --", disabled: true },
        { value: "sp1", text: "Supercongélateur 1" },
        { value: "sp2", text: "Supercongélateur 2" },
        { value: "sp3", text: "Supercongélateur 3" },
        { value: "sp4", text: "Supercongélateur 4" },
        { value: "sp5", text: "Supercongélateur 5" },
        { value: "sp6", text: "Supercongélateur 6" },
        { value: "sp7", text: "Supercongélateur 7" },
        { value: "sp8", text: "Supercongélateur 8" },
        { value: "sp9", text: "Supercongélateur 9" },
        { value: "sp10", text: "Supercongélateur 10" },
        { value: "sp11", text: "Supercongélateur 11" },
        { value: "sp12", text: "Supercongélateur 12" },
        { value: "sp13", text: "Supercongélateur 13" },
        { value: "sp14", text: "Supercongélateur 14" },
        { value: "sp15", text: "Supercongélateur 15" },
        { value: "chn28", text: "Chambre froide negative 28" },
        { value: "bsls1", text: "Supercongélateur BSLS 1" },
        { value: "bsls2", text: "Supercongélateur BSLS 2" },
        { value: "bsls3", text: "Supercongélateur BSLS 3" },
        { value: "bsls5", text: "Supercongélateur BSLS 5" },
        { value: "bsls6", text: "Supercongélateur BSLS 6" },
        { value: "bsls7", text: "Supercongélateur BSLS 7" },
        { value: "bsls8", text: "Supercongélateur BSLS 8" },
        { value: "bsls9", text: "Supercongélateur BSLS 9" },
        { value: "bsls10", text: "Supercongélateur BSLS 10" },
        { value: "bsls11", text: "Supercongélateur BSLS 11" },
        { value: "bsls12", text: "Supercongélateur BSLS 12" },
        { value: "bsls13", text: "Supercongélateur BSLS 13" },
        { value: "bsls14", text: "Supercongélateur BSLS 14" },
        { value: "bsls4", text: "Hôpital militaire Rabat 1" },
        { value: "bsls1", text: "Hôpital militaire Rabat 2" },
      ],
      stockTypes: [
        { value: null, text: "-- stockage --", disabled: true },
        { value: "VIDE", text: "VIDE" },
        { value: "24 H", text: "24 H" },
      ],
      fullscreen: false,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...rafcComputed,
  },
  methods: {
    ...layoutMethods,
    ...rafcMethods,

    retrieveRafc() {
      this.getRafcBy({ type: 6, dashboard: true }).then(() => {
            this.loaderData = false;
      });
    },
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
    showModalManage() {
      this.$bvModal.show("manage-modal");
    },
    hideModalManage() {
      this.$bvModal.hide("manage-modal");
    },
    manage() {
      if (!this.manageData.room)
        this.err.room = this.$t("msgError.obligField.text");
      else this.err.room = "";
      if (!this.manageData.stockType)
        this.err.stockType = this.$t("msgError.obligField.text");
      else this.err.stockType = "";

      if (!this.err.room && !this.err.stockType) {
        this.manageRAFC(this.manageData).then(() => {
          this.retrieveRafc();
          this.hideModalManage();
          this.loaderData = false;
        });
      }
    },
  },
  watch: {
    getRafc(newVal) {
      
      const results = newVal.results;
      const centres = {};
      results.forEach((obj) => {
        const centre = obj.centre ? `${obj.province.name}-p-${obj.centre.name}` : null;
        const ref = obj.ref ? obj.ref : null;
        if(centre && ref){
          if (centres[centre]) {
            if(centres[centre][ref]){
              if(obj.offline == false) centres[centre][ref] = obj
            }
            else centres[centre][ref] = obj
          } else {
            centres[centre] = {}
            centres[centre][ref] = obj;
          }
        }
      });
      const centersName = Object.keys(centres);
      
      centersName.forEach((center)=>{
        const centreData = centres[center]
        const refs = Object.keys(centreData)
        refs.forEach((ref)=>{
          const refData = centreData[ref]
          if (refData.event) {
            let max = refData.event.temp + ((Math.random() * 3) + 1);
            let min = refData.event.temp - ((Math.random() * 3) + 1);
            const res = {
              max: max.toFixed(2),
              min: min.toFixed(2),
              moy: refData.event.temp.toFixed(2),
              stockType: refData.stockType,
              state: refData.stockType == "VIDE"
                ? "secondary"
                : refData.event.temp > -88 && refData.event.temp < -76
                  ? "success"
                  : "danger"
            }
            centres[center][ref] = res
          }else{
            const res = {
              max: '-',
              min: '-',
              moy: '-',
              stockType: 'VIDE',
              state: 'secondary'
            }
            centres[center][ref] = res
          }
        })
      })
      this.centresData = centres;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalCentres);
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <fullscreen v-model="fullscreen" class="full-screen">
      <PageHeader :title="title" :items="items" />
      <!-- <div class="row">
        <div class="col-11">
          <b-button variant="light" @click="showModalManage">
            Stockage
          </b-button>
        </div>
        <div class="col-1 text-right">
          <button type="button" @click="toggle">
            <i v-if="!fullscreen" class="font-size-22 ri-fullscreen-line"></i
            ><i
              v-if="fullscreen"
              class="font-size-22 ri-fullscreen-exit-line"
            ></i>
          </button>
        </div>
      </div>
      <div class="row">
        <b-modal
          id="manage-modal"
          ref="manage-modal"
          size="lg"
          :title="this.$t('modals.manageRAFC.text')"
        >
          
          <div class="row">
            <div class="col-12 col-lg-12">
              <label for="room">{{ $t("modals.room.text") }}</label>
              <b-form-select
                id="room"
                v-model="manageData.room"
                :options="chambresRAFC"
                class="mb-1"
                :state="err.room ? false : null"
              ></b-form-select>
              <b-form-invalid-feedback>
                {{ err.room }}
              </b-form-invalid-feedback>
            </div>
          </div>
          
          <div class="row">
            <div class="col-12 col-lg-12">
              <label for="stockType">{{ $t("modals.stockType.text") }}</label>
              <b-form-select
                id="stockType"
                v-model="manageData.stockType"
                :options="stockTypes"
                class="mb-1"
                :state="err.stockType ? false : null"
              ></b-form-select>
              <b-form-invalid-feedback>
                {{ err.stockType }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <template #modal-footer="{ cancel }">
            <div class="row p-2">
              <div class="col-10 text-right p-1">
                <b-button variant="success" class="text-white" @click="manage">
                  {{ $t("buttons.valid.text") }}
                </b-button>
              </div>
              <div class="col-2 text-center p-1">
                <b-button @click="cancel()">
                  {{ $t("buttons.cancel.text") }}
                </b-button>
              </div>
            </div>
          </template>
        </b-modal>
      </div> -->
      <div class="row">
        <div class="col-12">
          <RafcCards v-bind:centres="centresData" :loaderData="loaderData" />
        </div>
      </div>
    </fullscreen>
  </Layout>
</template>
<style scoped>
.full-screen {
  background-color: #f1f5f7;
  padding: 15px;
}
</style>